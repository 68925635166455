module.exports = {
    api: require('./api'),
    init: require('./functions/init'),
    isReady: require('./functions/isReady'),
    identify: require('./functions/identify'),
    track: require('./functions/track'),
    page: require('./functions/page'),
    reset: require('./functions/reset'),
    propertiesLoaded: require('./functions/propertiesLoaded'),
    runningExperiments: require('./functions/runningExperiments'),
    variable: require('./functions/variable'),
    codeBlock: require('./functions/codeBlock'),
    featureFlagEnabled: require('./functions/featureFlag'),
    runningFeatureFlags: require('./functions/runningFeatureFlags'),
    hasUserOptedOutTracking: require('./functions/hasUserOptedOutTracking'),
    optOutTracking: require('./functions/optOutTracking'),
    optInTracking: require('./functions/optInTracking'),
    startNewSession: require('./functions/startNewSession'),
}
