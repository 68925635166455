const session = require('../lib/session')
const _ = require('../lib/tools')

// return running experiments and variations when session data is loaded
module.exports = (callback) => {
    session.waitForFetchPromise(() => {
        const featureFlags = (session.config && session.config.ff ? session.config.ff : {})
        const runningFF = {}
        _.values(featureFlags).forEach((ff) => {
            if (ff.enabled) {
                runningFF[ff.key] = ff.name
            }
        })
        return callback && callback(runningFF)
    }, 10000)
}
