const request = require('./base')
const users = require('./users')
const sdkSettings = require('../../sdkSettings')
const log = require('../lib/logger')
const merge = require('../lib/merge')
const location = require('../lib/location')
const Queue = require('../lib/queue')
const session = require('../lib/session')
const cookies = require('../lib/cookies')

const events_path = 'events'
const eventsQueue = new Queue()
let eventTypes = {
    active: 'appActive',
    terminate: 'appTerminate',
    config: 'tlClientConfig',
    fastConfig: 'tlFastModeConfig',
    goal: 'goalAchieved',
    pageView: 'viewAppeared',
    pageClose: 'viewDisappeared',
    timeOnPage: 'viewTimeOnPage',
    adobeAnalytics: 'Adobe'
}

exports.types = eventTypes

exports.watchLifecycleEvents = () => {
    exports.appActive()

    let flushQueueFunc = flushQueue.bind(this)
    window.addEventListener('unload', () => {
        log.log("Window on unload", null, log.DEBUG)
        exports.appTerminate()
        saveQueueToLocalStorage()
    })
}

exports.timeOnPage = function(category, name, href, title, location, startDate) {
    let eventObject = defaultEventObject(eventTypes.timeOnPage)

    if (startDate && startDate.getTime) {
        let nowTime = (new Date()).getTime()
        let startTime = startDate.getTime()
        eventObject.val = (nowTime - startTime) / 1000
    }

    eventObject.vKey = name
    eventObject.tKey = category
    eventObject.tvKey = title
    eventObject.tvCl = href

    if (location) {
        eventObject.data = merge(eventObject.data || {}, {_tl_view: location})
    }

    return eventsQueue.enqueue(eventObject)
}

exports.scheduleTick = () => {
    let flushQueueFunc = flushQueue.bind(this)
    setTimeout(flushQueueFunc, sdkSettings().eventsFlushQueueTimeout)
}

exports.pageClose = function(category, name, href, title, location) {
    let eventObject = defaultEventObject(eventTypes.pageClose)

    eventObject.val  = (new Date()).toISOString()
    eventObject.vKey = name
    eventObject.tKey = category
    eventObject.tvKey = title
    eventObject.tvCl = href

    if (location) {
        eventObject.data = merge(eventObject.data || {}, {_tl_view: location})
    }

    return eventsQueue.enqueue(eventObject)
}

exports.pageView = function(category, name, attrs) {
    let eventObject = defaultEventObject(eventTypes.pageView)
    if (attrs) {
        eventObject.data = merge(eventObject.data, attrs)
    }

    eventObject.val = (new Date()).toISOString()
    eventObject.vKey = name
    eventObject.tKey = category

    return eventsQueue.enqueue(eventObject)
}

exports.goalAchieved = function(event_name, value, attrs) {
    let eventObject = defaultEventObject(eventTypes.goal)

    if (attrs)
        eventObject.data = merge(eventObject.data, attrs)
    if (value)
        eventObject.val = value

    eventObject.gn = event_name
    return eventsQueue.enqueue(eventObject)
}

exports.trackAdobeAnalyticsEvents = function(event_name, value, attrs) {
    let eventObject = defaultEventObject(eventTypes.adobeAnalytics)

    if (attrs)
        eventObject.data = merge(eventObject.data, attrs)
    if (value)
        eventObject.val = value

    eventObject.gn = event_name
    return eventsQueue.enqueue(eventObject)
}

exports.appActive = () => {
    return eventsQueue.enqueue(defaultEventObject(eventTypes.active))
}

exports.appTerminate = () => {
    return eventsQueue.enqueue(defaultEventObject(eventTypes.terminate))
}

function logTimeEvent(event, time) {
    let now = new Date()
    event.val = (now.getTime() - time.getTime()) / 1000.0
    return eventsQueue.enqueue(event)
}

exports.clientConfig = (time) => {
    if (!time) return
    return logTimeEvent(defaultEventObject(eventTypes.config), time)
}

exports.fastModeConfig = (time) => {
    if (!time) return
    return logTimeEvent(defaultEventObject(eventTypes.fastConfig), time)
}

exports.post = (events, callback) => {
    let time = new Date()
    let params = {}
    let payloadDatum = (even) => {
        let sessionAttrs = {}
        sessionAttrs.sid = session.getSessionID()
        return {
            session: sessionAttrs,
            events: events
        }
    }
    request.post(events_path, params, payloadDatum, (err, response) => {
        if (!err) {
            log.time("Taplytics::events.post: succesfully logged events.", response, time, log.DEBUG)
        } else {
            log.error("Taplytics::events.post: failed to log events", err, log.LOG)
        }

        return callback && callback(err, response)
    })
}

//
// Internal functions
//
function defaultEventObject(type) {
    return {
        type: type,
        date: (new Date()).toISOString(),
        tvKey: location.attr('title'),
        tvCl: location.attr('href'),
        prod: sdkSettings().production ? 1 : 0,
        data: {
            _tl_view: location.toObject()
        }
    }
}

function flushQueue() {
    log.log("events.flushQueue: tick.", eventsQueue, log.LOUD)
    if (eventsQueue.isEmpty() || !session.hasLoadedData) {
        return exports.scheduleTick()
    }

    // Flush eventsQueue.
    let events = eventsQueue.flush()
    let sessionID = session.getSessionID()
    let lsEvents = cookies.getLS(eventsQueueKey)
    if (lsEvents && lsEvents.length) {
        log.log(`Add ${lsEvents.length} events from local storage cache`, null, log.DEBUG)
        events = events.concat(lsEvents)
        cookies.expire(eventsQueueKey, true)
    }

    // Queue up a session request if we don't have a session ID.
    if (!sessionID) {
        users.post({}, "Taplytics::events.flushQueue: failed to create sessions. Events will fail to process.")
    }

    exports.post(events, (err, response) => {
        if (err) { // Something went wrong. Add them back to the queue!
            eventsQueue.enqueueAll(events)
        }

        exports.scheduleTick()
    })
}

let eventsQueueKey = "_tl_eventsQueue"
function saveQueueToLocalStorage() {
    if (eventsQueue.isEmpty() || !session.hasLoadedData) return
    let events = eventsQueue.flush()
    log.log(`save ${events.length} events to local storage`, eventsQueue, log.DEBUG)
    cookies.setLS(eventsQueueKey, events)
}
