const log = require('../lib/logger')
const request = require('../api/base')

// This class holds callback promises and ensures that they will get called
// before the request.timeout time.
function CallbackPromiseHandler(opts) {
    this.promises = []
    this.name = opts ? opts.name : null
}

CallbackPromiseHandler.prototype.push = function(callback, timeout) {
    let hasExecutedCallback = false
    let self = this

    function executeCallback(value) {
        if (hasExecutedCallback) return

        if (callback) {
            hasExecutedCallback = true
            callback(value)
        }
    }

    this.promises.push(executeCallback)

    setTimeout(() => {
        if (!hasExecutedCallback) {
            log.log(self.name + " promise timed out", null, log.LOG)
            executeCallback()
        }
    }, timeout || request.timeout)
}

CallbackPromiseHandler.prototype.completePromises = function(value) {
    for (const promise of this.promises) {
        if (promise) promise(value)
    }
    this.promises = []
}

module.exports = CallbackPromiseHandler
