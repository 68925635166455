const Cookies = require('./cookies')
const uuidGenerator = require('uuid')
const log = require('./logger')
const sdkSettings = require('../../sdkSettings')
const configAPI = require('../api/config')
const source = require('./source')
const location = require('./location')
const CallbackPromiseHandler = require('./callbackPromiseHandler')
const domObserver = require('../visualEditor/domObserver')
const hasUserOptedOutTracking = require('../functions/hasUserOptedOutTracking')
const uuid = require('uuid/v4');

let cookieConfig = {
    cookieSessionID: '_tl_csid',
    deviceUUID: '_tl_duuid',
    sessionUUID: '_tl_suuid', // old uuid used only for upgrade path
    userID: '_tl_uid',
    // Correspond to models on our system:
    sessionID: '_tl_sid',
    appUserID: '_tl_auid',
    cachedConfig: '_tl_config',
    sessionOptions: function(sessionID, key) {
        if (!sessionID || !key)  return null

        return `tl_sopts_${sessionID}_${key}`
    }
}

// Session Data
exports.config = null
// if the session has loaded data from our servers
exports.hasLoadedData = false

exports.sessionExpirationMin = 30

//
// Lifecycle methods
//
exports.start = function(deviceUUID) {
    return exports.updateCookieSession().setDeviceUUID(deviceUUID)
}

// Sets a session variable (only accessible during this session)
exports.get = function (key, is_json) {
    if (!key) return undefined

    exports.tick()

    let sessionID = exports.getCookieSessionID()
    let cookieKey = cookieConfig.sessionOptions(sessionID, key)
    if (!cookieKey) return undefined

    if (!is_json || !(JSON && JSON.parse)) {
        return Cookies.get(cookieKey)
    } else {
        let val = Cookies.get(cookieKey)
        if (val && (typeof val === "object")) return val

        try {
            return JSON.parse(val)
        } catch (err) {
            log.error("Session.get(" + key + ") failed to parse JSON.", err, log.DEBUG)
            return val
        }
    }
}

exports.tick = function() {
    exports.updateCookieSession() // Make sure we reset the expiration
    return exports
}

exports.set = function(key, value, is_json) {
    if (!key || value === undefined || (("" + value).length === 0)) {
        return false
    }

    exports.tick()

    let sessionID = exports.getCookieSessionID()
    let cookieKey = cookieConfig.sessionOptions(sessionID, key)
    let expirationDate = getSessionExpirationDate()

    let clean_value = value
    if (!cookieKey) {
        return false
    }

    if (is_json && JSON && JSON.stringify) {
        clean_value = JSON.stringify(value)
    }

    setIfOptedIn(cookieKey, clean_value, {expires: expirationDate})

    return true
}

// Unsets a session variable
exports.unset = function(key) {
    if (!key) return false

    exports.tick()

    let sessionID = exports.getCookieSessionID()
    let cookieKey = cookieConfig.sessionOptions(sessionID, key)

    Cookies.expire(cookieKey)
}

//
// Setters
//
exports.updateCookieSession = function() {
    let cookieSessionID = exports.getCookieSessionID()
    if (!cookieSessionID) { // We've expired!
        cookieSessionID = uuidGenerator.v4()
        exports.deleteSessionID()
    }

    let expirationDate = getSessionExpirationDate()

    setIfOptedIn(cookieConfig.cookieSessionID, cookieSessionID, {expires: expirationDate})
    log.log("Set cookieSessionID to: " + cookieSessionID, {expires: expirationDate}, log.LOUD)

    return exports
}

exports.setDeviceUUID = function(deviceUUID) {
    deviceUUID = deviceUUID || exports.getDeviceUUID()
    if (!deviceUUID) {
        deviceUUID = uuidGenerator.v4()
    }

    setIfOptedIn(cookieConfig.deviceUUID, deviceUUID, {expires: new Date(2147483647000)})
    log.log("Set deviceUUID to: " + deviceUUID, null, log.DEBUG)

    return exports
}

exports.setSessionID = function(sessionID) {
    setIfOptedIn(cookieConfig.sessionID, sessionID, {expires: getSessionExpirationDate()})
    log.log("Set sessionID to: " + sessionID, null, log.DEBUG)
    return exports
}

exports.deleteSessionID = function() {
    Cookies.expire(cookieConfig.sessionID)
    return exports
}

exports.setAppUserID = function(appUserID) {
    setIfOptedIn(cookieConfig.appUserID, appUserID, {expires: getSessionExpirationDate()})
    log.log("Set appUserID to: " + appUserID, null, log.DEBUG)
    return exports
}

exports.deleteAppUserID = function() {
    Cookies.expire(cookieConfig.appUserID)
    return exports
}

exports.deleteSessionUUID = function() {
    Cookies.expire(cookieConfig.sessionUUID)
}

exports.setUserID = function(user_id) {
    if (configAPI.userBucketing && !user_id) {
        let anonID = 'TL_Anon_' + uuid()
        log.log("setting anon id", anonID)
        Cookies.set(cookieConfig.userID, anonID)
    } else if (user_id) {
        Cookies.set(cookieConfig.userID, user_id)
    }
    return exports
}

exports.resetUserID = function() {
    if (configAPI.userBucketing) {
        exports.setUserID(null)
    } else {
        Cookies.expire(cookieConfig.userID)
    }
    return exports
}

exports.setCachedConfig = function(config) {
    setJSONIfOptedIn(cookieConfig.cachedConfig, {
        expVarsNamesHistory: config ? config.expVarsNamesHistory : {},
        expVarsNames: config ? config.expVarsNames : {},
        expVarsIds: config ? config.expVarsIds : {},
        dynamicVars: config ? config.dynamicVars : {}
    }, null, true)
    return exports
}

//
// Getters
//
exports.getDeviceUUID = function() {
    // sessionUUID is an upgrade path from an old SDK version
    const sessionUUID = Cookies.get(cookieConfig.sessionUUID)
    if (sessionUUID) {
        return sessionUUID
    }

    const deviceUUID = Cookies.get(cookieConfig.deviceUUID)
    if (sessionUUID === deviceUUID) {
        exports.deleteSessionUUID()
    }
    return deviceUUID
}

exports.getCookieSessionID = function() {
    return Cookies.get(cookieConfig.cookieSessionID)
}

exports.getAppUserID = function() {
    return Cookies.get(cookieConfig.appUserID)
}

exports.getOrCreateUserID = function() {
    let savedUserID = exports.getUserID()
    if (!savedUserID) {
        // set random TL user id
        exports.setUserID(null)
        return exports.getUserID()
    }
    return savedUserID
}

exports.getUserID = function() {
    return Cookies.get(cookieConfig.userID)
}

exports.getSessionID = function() {
    return Cookies.get(cookieConfig.sessionID)
}

exports.getCachedConfig = function() {
    return Cookies.getJSON(cookieConfig.cachedConfig, true)
}

//
// Session Attributes
//
exports.getSessionAttributes = function(attr) {
    attr = (attr || {})
    let locationData = location.toObject() // document.location
    let sourceData = source() // document.referrer + location.search

    attr.sid = this.getSessionID()
    attr.ad  = this.getDeviceUUID()
    attr.adt = 'browser'
    attr.ct  = 'browser'
    attr.lv  = sdkSettings().production ? '0' : '1'
    attr.sdk = sdkSettings().sdkVersion
    attr.rfr = sourceData.referrer
    attr.ub = configAPI.userBucketing;

    attr.exm = sourceData.search.utm_medium
    attr.exs = sourceData.search.utm_source
    attr.exc = sourceData.search.utm_campaign
    attr.ext = sourceData.search.utm_term
    attr.exct = sourceData.search.utm_content

    attr.prms = {
        search: sourceData.search,
        location: locationData
    }

    if (navigator && navigator.userAgent) {
        attr.prms.userAgent = navigator.userAgent
    }

    return attr
}

exports.saveSessionConfig = function(config, errored) {
    if (errored && !config) {
        log.log("Using cached config because of server error", null, log.DEBUG)
        config = exports.getCachedConfig()
    }

    exports.config = config
    hasConfigData = true

    if (config && config.app_user_id && config.session_id) {
        exports.hasLoadedData = true
        exports.setAppUserID(config.app_user_id)
        exports.setSessionID(config.session_id)
        exports.tick()
    }

    if (config) {
        exports.setCachedConfig(config)
    }

    if (!domObserver.manualTriggerEdits) {
        domObserver.applyVisualEditsFromConfig(config)
    }
    configPromises.completePromises(!!config)

    // call all session config promises even if we have errored
    if (errored || (config && config.app_user_id && config.session_id)) {
        sessionConfigPromises.completePromises(!!config)
    }
}

let sessionConfigPromises = new CallbackPromiseHandler({name: "sessionConfigPromises"})
exports.sessionConfigPromise = function(callback, timeout) {
    if (!callback) return
    if (exports.hasLoadedData && exports.config) {
        return callback(true)
    } else {
        sessionConfigPromises.push(callback, timeout)
    }
}

let hasConfigData = false
let configPromises = new CallbackPromiseHandler({name: "configPromises"})
exports.configPromise = function(callback) {
    if (!callback) return
    if (hasConfigData && exports.config) {
        return callback(true)
    } else {
        configPromises.push(callback)
    }
}

exports.waitForFetchPromise = (callback, timeout) => {
    if (!callback) return

    exports.sessionConfigPromise((success) => {
        if (!success) {
            return callback(false)
        } else if (exports.hasLoadedData) {
            return callback(true)
        }
        const interval = setInterval(() => {
            if (exports.hasLoadedData) {
                clearInterval(interval)
                callback(true)
            }
        }, 100)
    }, timeout)
}

exports.resetAll = function() {
    exports.deleteAppUserID()
    exports.resetUserID()
    exports.resetSession()
}

exports.resetSession = function() {
    exports.deleteSessionID()
    exports.config = null
    exports.hasLoadedData = false
}

function setIfOptedIn(key, value, options) {
    if (hasUserOptedOutTracking()) {
        return
    }
    if (options) {
        Cookies.set(key, value, options)
    } else {
        Cookies.set(key, value)
    }

}

function setJSONIfOptedIn(key, value, options, useLS) {
    if (hasUserOptedOutTracking()) {
        return
    }
    Cookies.setJSON(key, value, options, useLS)
}

//
// Helper Functions
//
function getSessionExpirationDate() {
    return dateAdd(new Date(), 'minute', exports.sessionExpirationMin)
}

function dateAdd(date, interval, units) { // Thank you SO: http://stackoverflow.com/questions/1197928/how-to-add-30-minutes-to-a-javascript-date-object
    let ret = new Date(date) //don't change original date
    switch (interval.toLowerCase()) {
        case 'year':
            ret.setFullYear(ret.getFullYear() + units)
            break
        case 'quarter':
            ret.setMonth(ret.getMonth() + 3 * units)
            break
        case 'month':
            ret.setMonth(ret.getMonth() + units)
            break
        case 'week':
            ret.setDate(ret.getDate() + 7 * units)
            break
        case 'day':
            ret.setDate(ret.getDate() + units)
            break
        case 'hour':
            ret.setTime(ret.getTime() + units * 3600000)
            break
        case 'minute':
            ret.setTime(ret.getTime() + units * 60000)
            break
        case 'second':
            ret.setTime(ret.getTime() + units * 1000)
            break
        default:
            ret = undefined
            break
    }
    return ret
}
