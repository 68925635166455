// import
const _ = require('../lib/tools')
const log = require('../lib/logger')

// modules
const session = require('../lib/session')
const URLUtils = require('./urlUtils')
const VisualEditor = require('./visualEditor')

let observer
exports.manualTriggerEdits = false

const startDOMObserve = () => {
    if (!!exports.manualTriggerEdits) {
        return log.log('Manual trigger for edits enabled, disabling DOM observe', null, log.DEBUG)
    }
    if (typeof MutationObserver === "undefined" || !document.body) {
        return
    }
    log.log("start DOM Observer", null, log.DEBUG)
    const applyChanges = (mutationsList) => {
        log.log(`DOM mutation observed`, null, log.DEBUG)
        exports.applyVisualEditsFromConfig(session.config, mutationsList)
    }
    if (!observer) {
        observer = new MutationObserver(_.debounce(applyChanges, 50, {leading: true}))
    }

    exports.startMutationObserver()

    applyChanges()
}

exports.startMutationObserver = () => {
    if (observer) {
        observer.observe(document.body, {
            attributes: true,
            childList: true,
            characterData: true,
            subtree: true,
            attributeFilter: [],
            characterDataOldValue: false,
            attributeOldValue: false
        })
    }
}

exports.stopDOMObserve = () => {
    if (observer) {
        observer.disconnect()
    }
}

/*
 * TODO:

 * This is some old code that uses an interval to wait for the
 * dom before starting the dom observer, should be replaced
 * with a dom event listener
 */
let interval = setInterval(() => {
    if (document.body) {
        startDOMObserve()
        clearInterval(interval)
        interval = null
    }
}, 1)

function pruneModifications(modifications) {
    let cssModification = null;
    let jsModification = null;
    let prunedModifications = [];

    modifications.forEach((modification) => {
        const outerHTML = modification.attributes.outerHTML
        if (outerHTML && outerHTML.startsWith('<style')) {
            cssModification = modification;
        } else if (modification.attributes.script) {
            jsModification = modification;
        } else {
            prunedModifications.push(modification);
        }
    })

    if (cssModification) prunedModifications.unshift(cssModification);
    if (jsModification) prunedModifications.push(jsModification);

    return prunedModifications;
}

/**
 *  Apply all experiment changes to page
 */
exports.applyVisualEditsFromConfig = (config, mutationsList = []) => {
    if (!config || !config.webModifications || !config.webElements) return
    log.log("Apply visual edits from config", null, log.DEBUG)
    let currentURL = URLUtils.buildUrlFromLocation(window.location)

    pruneModifications(config.webModifications).forEach((modification) => {
        if (!modification) return

        let element = config.webElements.find((e) => e._id === modification._element)
        if (!element) {
            return log.error(`No web element found for _id: ${modification._element}, modification: ${modification._id}`, null, log.DEBUG)
        }

        const applyModification = mutationsList.length ? mutationsList.some((mutation) => {
            const isCharacterData = mutation.type === 'characterData' && modification.attributes.modType === 'text'
            const isWithinMutatedParent = mutation.target.contains(document.querySelector(element.selector))
            return isCharacterData || isWithinMutatedParent
        }) : true

        if (!applyModification) return

        if (config.urlTargetingRules && URLUtils.matchUrlRules(config.urlTargetingRules, currentURL)) {
            VisualEditor.applyModificationsToDOM({element, modification})
            return
        }

        if (!element.hostnameMatch && !URLUtils.matchUrlsByPath(element.url, currentURL)) {
            return
        }

        VisualEditor.applyModificationsToDOM({element, modification})
    })
}
