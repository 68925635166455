const Mixpanel = () => window.mixpanel

const logTo = (expDic) => {
    if (!expDic || !Mixpanel()) {
        return
    }
    const expsArray = Object.entries(expDic)
                                .map((entry) => entry.join(':'))
    const property = { TL_Experiments: expsArray }
    Mixpanel().register(property)
}
exports.logTo = logTo
