const log = require('../lib/logger')
const cookies = require('../lib/cookies')
const OPT_OUT_KEY = require('../lib/constants').OPT_OUT_KEY

module.exports = function() {
    cookies.setLS(OPT_OUT_KEY, true);
    cookies.removeAllCookies()
    return this
}

