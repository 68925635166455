const cookies = require('../lib/cookies')
const OPT_OUT_KEY = require('../lib/constants').OPT_OUT_KEY

module.exports = function() {
    if (cookies.hasUserOptedOutTracking()) {
        cookies.setLS(OPT_OUT_KEY, false);
        this.init(this._in.token, this.api.config.startOptions)
    }
    return this
}
