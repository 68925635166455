function settings() {
    let app = require('./app/app')
    let config = {cdnAPI: "https://cdn-config.taplytics.com/js/"}

    if (app.env === 'production') {
        config.baseAPI = "https://api.taplytics.com/public_api/v1/"
        config.eventsAPI = "https://ping.taplytics.com/public_api/v1/"
    } else if (app.env === 'dev' || app.env === 'development') {
        config.baseAPI = "https://dev.taplytics.com/public_api/v1/"
        config.eventsAPI = "https://ping-dev.taplytics.com/public_api/v1/"
    } else if (app.env === 'stag' || app.env === 'staging') {
        config.baseAPI = "https://api-staging.taplytics.com/public_api/v1/"
        config.eventsAPI = "https://ping-staging.taplytics.com/public_api/v1/"
    } else if (app.env === 'staging-upcoming') {
        config.baseAPI = "https://api-staging-upcoming.taplytics.com/public_api/v1/"
        config.eventsAPI = "https://ping-staging-upcoming.taplytics.com/public_api/v1/"
    } else if (app.env === 'local') {
        config.baseAPI = "http://localhost:3001/public_api/v1/"
    }

    if (app && app.api && app.api.config) {
        const {apiAlias, pingAlias} = app.api.config
        if (apiAlias) {
            config.baseAPI = apiAlias + '/public_api/v1/'
        }
        if (pingAlias) {
            config.eventsAPI = pingAlias + '/public_api/v1/'
        }
    }

    config.eventsFlushQueueTimeout = 4000
    config.functionFlushQueueTimeout = 500
    config.sdkVersion = __VERSION__
    config.cookieDomain = (app && app.api && app.api.config && app.api.config.cookieDomain) ? app.api.config.cookieDomain : null
    config.production = app.env === 'production'

    return config
}

module.exports = settings
