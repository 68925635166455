function Queue() {
    let queue  = []

    this.length = function() {
        return queue.length
    }

    this.isEmpty = function() {
        return (queue.length === 0)
    }

    this.enqueue = function(item) {
        queue.push(item)
    }

    this.enqueueAll = function(items) {
        if (!items || (items && typeof items !== "array")) {
            return 0
        }

        for (const item of items) {
            this.enqueue(item)
        }

        return items.length
    }

    this.dequeue = function() {
        if (queue.length === 0) return undefined
        return queue.shift()
    }

    this.flush = function() {
        let oldQueue = queue.slice()
        queue = []
        return oldQueue
    }

    this.peek = function() {
        return (queue.length > 0 ? queue[0] : undefined)
    }
}

module.exports = Queue
