const log = require('../lib/logger')
const session = require('../lib/session')

module.exports = function() {
    if (!this.isReady()) {
        log.error("Taplytics::reset: you have to call Taplytics.init first.", null, log.USER)
        return false
    }

    session.tick()
    this.api.users.del()
    session.resetAll()
    this.api.config.get({})
    return this
}
