const log = require('../lib/logger')
const session = require('../lib/session')

const failureMessage = 'Taplytics::identify: failed to save the user attributes properly.'

module.exports = function(attrs) {
    if (!this.isReady()) {
        log.error('Taplytics::identify: you have to call Taplytics.init first.', null, log.USER)
        return this
    }
    if (!isValidAttrs(attrs)) {
        log.error('Taplytics::identify: you have to pass in an object with user attributes.', null, log.USER)
        return this
    }

    if (attrs.user_id || attrs.email) {
        session.hasLoadedData = false
    }

    const parsedAttrs = parseAttrs(attrs)
    const savedAppUserId = session.getAppUserID()

    const appUserUpdateCallback = (err, response) => {
        if (err) {
            return
        }
        const body = response.body
        const appUserId = body && body.app_user_id
        if (appUserId !== savedAppUserId) {
            /*
             * not sure if its bad for auid to be in the user_attributes
             */
            const { auid, ...rest} = parsedAttrs
            this.api.config.get({ user_attributes: rest })
        }
    }

    this.api.users.post(parsedAttrs, failureMessage, appUserUpdateCallback)

    return this
}

//
// Helpers
//

function isValidAttrs(attrs) {
    return !(!attrs || (attrs && (typeof attrs !== 'object')))
}

const acceptedUserAttKeys = {
    'user_id': 'user_id',
    'id': 'user_id',
    'userID': 'user_id',
    'userId': 'user_id',
    'customer_id': 'user_id',
    'member_id': 'user_id',
    'email': 'email',
    'email_address': 'email',
    'name': 'name',
    'fullName': 'name',
    'full_name': 'name',
    'firstName': 'firstName',
    'first_name': 'firstName',
    'lastName': 'lastName',
    'last_name': 'lastName',
    'avatar': 'avatarUrl',
    'avatarUrl': 'avatarUrl',
    'avatar_url': 'avatarUrl',
    'age': 'age',
    'gender': 'gender'
}

const userIDKeys = [
    'user_id',
    'userID',
    'userId'
]

function parseAttrs(attrs) {
    const userAttrs = {customData: {}}

    for (const key of Object.keys(attrs)) {
        const value = attrs[key]
        const topLevelKey = acceptedUserAttKeys[key]

        if (topLevelKey) {

            if (userIDKeys.includes(topLevelKey)) {
                session.setUserID(value)
            }

            userAttrs[topLevelKey] = value
        } else {
            userAttrs.customData[key] = value
        }
    }

    return userAttrs
}

