const QS = require('qs')

module.exports = function() {
    let searchParams = {}
    let referrer = null

    if (location && location.search && location.search.length) {
        searchParams = QS.parse(location.search.substr(1))
    }
    if (document && document.referrer) {
        referrer = document.referrer
    }

    return {referrer: referrer, search: searchParams}
}
