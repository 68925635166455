const request = require('./base')
const log = require('../lib/logger')
const session = require('../lib/session')

exports.users_path = 'users'

//
// Requests
//
exports.del = (next) => {
    session.sessionConfigPromise(() => {
        let appUserID = session.getAppUserID()
        if (!appUserID) return

        let sessionAttrs = {}
        sessionAttrs.sid = session.getSessionID()
        sessionAttrs.ad  = session.getDeviceUUID()

        let params = {}
        let body = {session: sessionAttrs}
        log.log("users_del", body, log.DEBUG)

        request.del(exports.users_path + "/" + appUserID, params, body, (err, response) => {
            if (err) {
                log.error("Couldn't properly rest user.", response, log.DEBUG)
            } else {
                log.log("Successfully reset the user.", response, log.DEBUG)
            }

            return next && next(err, response)
        })
    })
}

exports.post = (user_attrs, failure_message, next) => {
    session.sessionConfigPromise(() => {
        let appUser = user_attrs
        let sessionAttrs = session.getSessionAttributes()
        if (!appUser) appUser = {}
        appUser.auid = session.getAppUserID()

        let time = new Date()
        let body = {
            session: sessionAttrs,
            app_user: appUser
        }

        log.log("users_post", JSON.stringify(body), log.DEBUG)

        request.post(exports.users_path, {}, body, (err, response) => {
            if (err) {
                log.error(failure_message, err, log.USER)
            } else {
                let data = response.body
                if (data) {
                    log.time("Users.post: successfully created/updated user.", response, time, log.DEBUG)
                    session.setAppUserID(data.app_user_id)
                    session.setSessionID(data.session_id)
                    session.tick()
                } else {
                    log.error(failure_message, null, log.USER)
                }
            }
            return next && next(err, response)
        })
    })
}
