const log = require('./logger')
const _ = require('./tools')
const sdkSettings = require('../../sdkSettings')

let lscache = require('lscache')
const CookieJar = require('cookiejar').CookieJar
const Cookie = require('cookiejar').Cookie
const CookieAccess = require('cookiejar').CookieAccessInfo

const OPT_OUT_KEY = require('./constants').OPT_OUT_KEY

if (!lscache.supported()) {
    log.log("Local Storage not supported", null, log.DEBUG)
    lscache = null
}

//Values that dont have illegal characters/dont need to be encoded are added to cookieValsToNotEncode
const cookieValsToNotEncode = {
    cookieSessionID: '_tl_csid',
    deviceUUID: '_tl_duuid',
    sessionUUID: '_tl_suuid', // old uuid used only for upgrade path
    // Correspond to models on our system:
    sessionID: '_tl_sid',
    appUserID: '_tl_auid',
    userID: '_tl_uid'
}


exports.getJSON = function(key, useLS) {
    let jsonValue = exports.get(key, useLS)
    if (jsonValue && _.isString(jsonValue)) {
        try {
            return JSON.parse(jsonValue)
        } catch(ex) {
            log.error("JSON parse cookie value", ex, log.DEBUG)
            return null
        }
    } else {
        return jsonValue
    }
}

exports.getLS = function(key) {
    if (!lscache) return null

    let value = lscache.get(key)
    log.log("Got local storage key: " + key + " with value: " + value, null, log.LOUD)
    return value
}

exports.get = function(key, useLS) {
    if (exports.getCookieSupport() && !useLS) {
        let jar = getJar()
        let accessInfo = new CookieAccess(exports.getCookieDomain())
        let cookie = jar.getCookie(key, accessInfo)
        if (cookie && !_.includes(_.values(cookieValsToNotEncode), key)) {
            cookie.value = decodeFromBase64(cookie.value)
        }
        return cookie ? cookie.value : undefined
    } else if (lscache) {
        return exports.getLS(key)
    } else if (useLS) {
        return exports.get(key)
    }
}

exports.setJSON = function(key, value, options, useLS) {
    let jsonStr
    try {
        if (_.isString(value)) {
            jsonStr = value
        } else {
            jsonStr = JSON.stringify(value)
        }
    } catch(ex) {
        log.error("JSON stringify cookie value", ex, log.DEBUG)
    }
    exports.set(key, jsonStr, options, useLS)
}

exports.set = function(key, value, options, useLS) {
    if (!key) return
    
    if (exports.getCookieSupport() && !useLS) {
        value   = value || ""
        options = options || {}
        let cookieJar = getJar()
        let cookieToSet = keyValueToCookie(key, value, options.expires)
        let cookieStr = cookieToSet.toString()
        document.cookie = cookieStr
        cookieJar.setCookie(cookieStr)
        log.log("Setting cookies to:", cookieStr, log.LOUD)
    } else if (lscache) {
        exports.setLS(key, value, options)
    } else if (useLS) {
        exports.set(key, value, options)
    }
}

exports.setLS = function(key, value, options) {
    if (!lscache) return

    let expiry = (options && options.expires) ? 30 : null
    lscache.set(key, value, expiry)
    log.log(`Setting local storage key: ${key} to value: ${value}`, null, log.LOUD)
}

exports.expire = function(key, useLS) {
    if (exports.getCookieSupport() && !useLS) {
        exports.set(key, "-", {expires: new Date()})
    } else if (lscache) {
        log.log("Deleting local storage key: " + key, null, log.LOUD)
        lscache.remove(key)
    } else if (useLS) {
        exports.expire(key)
    }
}

let staticJar
function getJar() {
    if (!staticJar) {
        staticJar = new CookieJar()
        updateJar()
    }
    return staticJar
}

function updateJar() {
    if (staticJar && document.cookie && document.cookie.length) {
        const ca = document.cookie.split(';')

        for (let i = 0; i < ca.length; i++) {
            try {
                let cValue = ca[i]
                while (cValue.charAt(0) === ' ') {
                    cValue = cValue.substring(1)
                }

                staticJar.setCookies(cValue)
            } catch(ex) {
                log.error("Exception setting cookie", ex, log.DEBUG)
            }
        }
    }
}

function keyValueToCookie(key, value, expiration) {
    if (!_.includes(_.values(cookieValsToNotEncode), key)) value = encodeToBase64(value)
    let cookieStr = key + "=" + value
    let cookie = new Cookie(cookieStr)
    if (expiration) {
        cookie.expiration_date = expiration
    }
    cookie.domain = exports.getCookieDomain()
    return cookie
}

function isIPAddress(hostname) {
    return hostname && hostname.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/)
}

exports.getCookieDomain = function() {
    const cookieDomain = sdkSettings().cookieDomain
    if (cookieDomain) return cookieDomain

    let hostname = window.location.hostname

    let domainParts = hostname && !isIPAddress(hostname) ? hostname.split('.').reverse() : null
    
    if (domainParts && domainParts.length >= 3) {
        // see if the second level domain is a common SLD.
        if (domainParts[1].match(/^(com|edu|gov|net|mil|org|nom|co|ca|name|info|biz)$/i)) {
            return `.${domainParts[2]}.${domainParts[1]}.${domainParts[0]}`
        }
    }
    return (domainParts && domainParts.length > 1) ? `.${domainParts[1]}.${domainParts[0]}` : null
}


exports.hasUserOptedOutTracking = function() {
    let opt_out = exports.getLS(OPT_OUT_KEY)
    if (opt_out === null) {
        if (lscache == null) return false
        lscache.set(OPT_OUT_KEY, false, null)
        log.log(`Setting local storage key: ${OPT_OUT_KEY} to value: ${false}`, null, log.LOUD)
        
        return false
    }
    return opt_out
}

exports.getCookies = function() {
    let cookieJar = getJar()
    let accessInfo = new CookieAccess(exports.getCookieDomain())
    let cookies = cookieJar.getCookies(accessInfo)
    return cookies
}

exports.removeAllCookies = function() {
    let cookieJar = getJar()
    let accessInfo = new CookieAccess(exports.getCookieDomain())
    let cookies = cookieJar.getCookies(accessInfo)
    
    // expire tl session cookies
    cookies.forEach((cookie) => {
        log.log(`expiring tl session cookie ${cookie.name}`, cookie, log.DEBUG)
        exports.expire(cookie.name, false)
    })
    
    // expire _tl cookies
    Object.keys(cookieValsToNotEncode).forEach((key) => {
        exports.expire(cookieValsToNotEncode[key], false)
    })
}

//
// Find out what cookies are supported. Returns:
// null - no cookies
// false - only session cookies are allowed
// true - session cookies and persistent cookies are allowed
// (though the persistent cookies might not actually be persistent, if the user has set
// them to expire on browser exit)
//
let cookieSupport = undefined
exports.getCookieSupport = function() {
    if (cookieSupport !== undefined) return cookieSupport

    let persist = true
    do {
        let c = 'gCStest=' + Math.floor(Math.random() * 100000000)
        //Check if persistent cookies are supported by setting an expiration for gCStest
        document.cookie = persist ? c + '; expires=' + new Date('Tue, 01-Jan-2030 00:00:00').toUTCString() : c
        //If the cookie is successfully set
        if (document.cookie.indexOf(c) !== -1) {
            //Invalidate the cookie by setting expiration to be in the past
            document.cookie = c + '; expires=' + new Date('Sat, 01-Jan-2000 00:00:00 GMT').toUTCString()
            cookieSupport = persist
            return persist
        }
    } while (!(persist = !persist))

    cookieSupport = null
    return null
}

function encodeToBase64(str) {
    //Use btoa to encode to base64 (btoa doesnt work well with unicode chars/chars > than 1 byte)
    //encodeURIComponent is used to handle unicode chars
    return btoa(encodeURIComponent(str))
}

function decodeFromBase64(str) {
    //decoding from base64 only works if it's a base64 encoded string (throws an error if the string is not)
    try {
        return decodeURIComponent(atob(str))
    } catch(exc) {

        //if an exception is caught it's because the string was never encoded
        return str
    }
}

