/**
 * This file is used to import lodash functions to keep the minified file size down. Also used so that we can require these functions
 * instead of importing them in other files which complicates testing using jest.
 */
import { debounce as _debounce, isNumber as _isNumber, isString as _isString, isObjectLike as _isObjectLike, values as _values, includes as _includes, isEqual as _isEqual, forEach as _forEach } from 'lodash'

export const isNumber = _isNumber
export const isString = _isString
export const debounce = _debounce
export const isObjectLike = _isObjectLike
export const values = _values
export const includes = _includes
export const isEqual = _isEqual
export const forEach = _forEach
