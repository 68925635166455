let override = {}

exports.toObject = function() {
    return {
        href: locationAttribute('href'),
        hash: locationAttribute('hash'),
        search: locationAttribute('search'),
        host: locationAttribute('host'),
        protocol: locationAttribute('protocol'),
        pathname: locationAttribute('pathname'),
        title: locationAttribute('title')
    }
}

exports.attr = function(key) {
    return locationAttribute(key)
}

exports.listen = function(app) {
    // TODO: implement
}


function locationAttribute(attr) {
    if (override[attr]) {
        return override[attr]
    }
    if (attr === 'title') {
        return document.title
    }

    return (document.location) ? document.location[attr] : null
}
