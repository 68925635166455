const log = require('../lib/logger')
const session = require('../lib/session')

module.exports = (key) => {
    if (!key) {
        log.error("No key to return feature flag enabled", null, log.USER)
        return false
    }

    const config = session.config
    if (!config || !config.ff) return false

    const ff = config.ff[key]
    return ff && ff.enabled
}
