const log = require('../lib/logger')
const DomObserver = require('./domObserver')

const styleTag = '<style'
const evaledModifications = []

exports.applyModificationsToDOM = ({element, modification}) => {
    DomObserver.stopDOMObserve()
    if (!element || !modification) return
    let htmlElements = exports.findElementsBySelector(element.selector)
    let htmlElement = (htmlElements && htmlElements.length) ? htmlElements[0] : null
    if (!htmlElement || evaledModifications.includes(modification._id)) {
        DomObserver.startMutationObserver()
        return
    }

    log.log(`Apply modification for web element: ${element._id}, modification: ${modification._id}`, null, log.LOUD)

    if (['globalCSS','globalScript','thisIsAFakeModType'].includes(modification.attributes.modType)) {
        evaledModifications.push(modification._id)
    }
    let { modType, content, outerHTML, script, property, value } = modification.attributes

    switch(modType) {
        case 'text':
            htmlElement.innerText = content
            break

        case 'HTML':
            htmlElement.outerHTML = content
            break

        case 'link':
            htmlElement.setAttribute('href', content)
            break

        case 'img':
            htmlElement.setAttribute('src', content)
            break

        case 'rearrange':
            const { position } = modification.attributes
            const secondaryElements = exports.findElementsBySelector(modification.attributes.secondaryElement)
            const secondaryElement = (secondaryElements && secondaryElements.length) ? secondaryElements[0] : null

            if (!secondaryElement || !secondaryElement.parentNode) {
                return
            }

            if (position === 'before') {
                secondaryElement.parentNode.insertBefore(htmlElement, secondaryElement)
            } else if (position === 'after') {
                secondaryElement.parentNode.insertBefore(htmlElement, secondaryElement.nextSibling)
            }

            break

        case 'display':
            if (content === 'visible') {
                if (htmlElement.style.display === 'none') {
                    htmlElement.style.removeProperty('display')
                } else if (htmlElement.style.visibility === 'hidden') {
                    htmlElement.style.removeProperty('visibility')
                }
            } else if (content === 'hidden') {
                if (htmlElement.style.display === 'none') {
                    htmlElement.style.removeProperty('display')
                }
                htmlElement.style.visibility = 'hidden'
            } else if (content === 'removed') {
                if (htmlElement.style.visibility === 'hidden') {
                    htmlElement.style.removeProperty('visibility')
                }
                htmlElement.style.display = 'none'
            }

            break

        case 'inlineStyle':
            const INLINE_STYLES_MAP = {
                fontFamily: 'font-family',
                fontSize: 'font-size',
                fontWeight: 'font-weight',
                fontColor: 'color',
                textAlign: 'text-align',
                backgroundColor: 'background-color',
                backgroundImage: 'background-image'
            }

            const inlineStyleCSSProperty = INLINE_STYLES_MAP[property]

            if (inlineStyleCSSProperty) {
                htmlElement.style[inlineStyleCSSProperty] = value
            }

            break

        case 'globalCSS':
            let existingStyleElement = htmlElement.querySelector('.tl-styles')

            if (existingStyleElement) {
                existingStyleElement.innerHTML = content
            } else {
                const newStyleElement = document.createElement('style')
                newStyleElement.type = 'text/css'
                newStyleElement.className = 'tl-styles'
                newStyleElement.innerHTML = content

                htmlElement.appendChild(newStyleElement)
            }
            break

        case 'globalScript':
            try {
                eval(content)
            } catch (err) {
                log.error(`Error on modification script for web element: ${element._id}, modification: ${modification._id}`, err, log.DEBUG)
            }
            break

        // no modType, so apply legacy schema
        default:
            if (script) {
                try {
                    eval(script)
                } catch (err) {
                    log.error(`Error on modification script for web element: ${element._id}, modification: ${modification._id}`, err, log.DEBUG)
                }
            } else if (outerHTML && htmlElement.outerHTML !== outerHTML) {
                if (outerHTML.startsWith(styleTag)) {
                    let existingStyle = htmlElement.querySelector('.tl-styles')
                    if (existingStyle) {
                        existingStyle.outerHTML = outerHTML
                    } else {
                        htmlElement.insertAdjacentHTML('beforeend', outerHTML)
                    }
                } else {
                    htmlElement.outerHTML = outerHTML
                }
            }
    }
    DomObserver.startMutationObserver()
}

exports.findElementsBySelector = (selector) => {
    return document.querySelectorAll(selector)
}
