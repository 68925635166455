
module.exports = function(destination, source) {
    for (let property in source) {
        if (source.hasOwnProperty(property)) {
            destination[property] = source[property]
        }
    }
    
    return destination
}
