const request = require('./base')
const log = require('../lib/logger')
const session = require('../lib/session')
const events = require('./events')
const _ = require('../lib/tools')

exports.fastMode = false
exports.startOptions = null
exports.cookieDomain = null
exports.userBucketing = false

exports.apiAlias = null
exports.pingAlias = null

// switch to get
exports.get = function({skipFastMode, dynamicConfig, user_attributes}) {
    let userID = user_attributes && user_attributes.user_id
    if (userID) {
        session.setUserID(userID)
    } else {
        userID = session.getOrCreateUserID()
    }

    if (dynamicConfig) {        
        return exports.handleDynamicConfig(dynamicConfig)
    }
    if (!skipFastMode && exports.fastMode && !session.test_experiments) {
        return exports.getFastModeConfig()
    }

    const time = new Date()
    const sessionAttrs = exports.buildSessionParams()
    try {
        // TODO: Remove the need to flatten customData once API handles custom user data correctly
        let aua = user_attributes
        if (aua && aua.customData) {
            aua = Object.assign({}, aua, aua.customData)
            delete aua.customData
        }
        sessionAttrs.aua = aua ? JSON.stringify(aua) : undefined
    } catch (e) {
        log.error("config_get: stringify user_attributes JSON", null, log.DEBUG)
    }

    if (exports.userBucketing) {
        sessionAttrs.uid = userID
    }

    log.log("config_get", sessionAttrs, log.DEBUG)
    request.get("config", sessionAttrs, function(err, response) {
        if (err) {
            log.error("Failed to get config", err, log.DEBUG)
            session.saveSessionConfig(null, true)
        } else {
            const data = response.body
            session.saveSessionConfig(data)

            if (data) {
                events.clientConfig(time)
                log.time("config.get: successfully got session config data", response, time, log.DEBUG)
            } else {
                log.error("No config data in response", null, log.DEBUG)
            }
        }
    })
}

exports.handleDynamicConfig = function(dynamicConfig) {
    if (!dynamicConfig) return
    
    log.log("handle dynamic config", dynamicConfig, log.DEBUG)
    session.saveSessionConfig(dynamicConfig)
    exports.postFastModeConfig()
}

exports.buildSessionParams = function() {
    const sessionAttrs = session.getSessionAttributes()
    sessionAttrs.auid = session.getAppUserID()
    if (sessionAttrs.prms) {
        sessionAttrs.prms = JSON.stringify(sessionAttrs.prms)
    }
    if (session.test_experiments) {
        sessionAttrs.uev = JSON.stringify(session.test_experiments)
    }
    return sessionAttrs
}

// POST fast mode config to create session
exports.postFastModeConfig = function() {
    const time = new Date()
    const sessionAttrs = exports.buildSessionParams()
    const config = session.config
    if (!config) return log.error("Missing config to POST", null, log.DEBUG)

    if (exports.userBucketing) {
        sessionAttrs.uid = session.getOrCreateUserID()
    }

    log.log("config_post", sessionAttrs, log.DEBUG)
    
    request.post("config", sessionAttrs, {expVarsIds: config.expVarsIds}, (err, response) => {
        if (err) {
            log.error("Failed to post config", err, log.DEBUG)
            session.saveSessionConfig(config, true)
        } else {
            const data = response.body
            if (data) {
                config.app_user_id = data.app_user_id
                config.session_id = data.session_id
                session.saveSessionConfig(config)

                events.clientConfig(time)
                log.time("config.post: successfully posted session config data", response, time, log.DEBUG)
            } else {
                log.error("No config data in post response", null, log.DEBUG)
                session.saveSessionConfig(config, true)
            }
        }
    })
    
}

exports.getFastModeConfig = function() {
    const time = new Date()
    log.log("get fastMode config", null, log.DEBUG)
    function skipFastMode() {
        log.log("Skipping fast mode, get server config", null, log.DEBUG)
        exports.get({skipFastMode: true})
    }
    
    request.getJSON(request.publicToken + ".json", (err, response) => {
        if (err || !response || !response.body) {
            log.error("Failed to get config", err, log.DEBUG)
            skipFastMode()
        } else {
            const data = exports.buildConfig(response.body)

            if (data) {
                session.saveSessionConfig(data)
                log.time("config.get: successfully got fast mode config data", response, time, log.DEBUG)
                events.fastModeConfig(time)
                exports.postFastModeConfig()
            } else {
                log.error("No config data in fast mode response", null, log.DEBUG)
                skipFastMode()
            }
        }
    })
}

exports.buildConfig = function(data) {
    const cachedConfig = session.getCachedConfig()
    const cachedExpVarsNames = cachedConfig && cachedConfig.expVarsNamesHistory && cachedConfig.expVarsNames || {}
    const expVarsNames = {}
    const cachedExpIds = cachedConfig && cachedConfig.expVarsIds || {}
    const expVarsIds = {}
    const dynamicVars = {}

    function addVariables(variables) {
        if (!variables || !variables.length) return

        for (const variable of variables) {
            if (variable.isActive) {
                if (!dynamicVars[variable.name]) {
                    dynamicVars[variable.name] = variable
                } else {
                    log.log("Warning dynamic variable is used in two experiments, name: " + variable.name, null, log.LOG)
                }
            }
        }
    }

    function chooseVariation(exp, variation) {
        if (variation === "b" || variation === "baseline") {
            expVarsIds[exp.id] = "b"
            cachedExpVarsNames[exp.name] = "baseline"
            expVarsNames[exp.name] = "baseline"
            addVariables(exp.baseline.dynamicVariables)
        } else if (variation) {
            cachedExpVarsNames[exp.name] = variation.name
            expVarsNames[exp.name] = variation.name
            expVarsIds[exp.id] = variation._id
            addVariables(variation.dynamicVariables)
        }
    }

    function findVariationId(exp, variationId) {
        if (!variationId) return null
        if (variationId === "baseline" || variationId === "b") return "b"

        for (let variation of exp.variations) {
            // check if variation _id or name equals variationId for backwards compatability.
            if (variation && (variation._id === variationId || variation.name === variationId)) {
                return variation
            }
        }
    }

    if (data.experiments) {
        for (const exp of data.experiments) {
            // Look for winning variation
            const wonVariation = findWinningVariation(exp)
            if (wonVariation) {
                chooseVariation(exp, wonVariation)
                continue
            }

            // find cached bucketed variation
            if (cachedExpVarsNames[exp.name] || cachedExpIds[exp.id]) {
                let variation = cachedExpIds[exp.id] || cachedExpVarsNames[exp.name]
                let foundVar = findVariationId(exp, variation)
                if (foundVar) {
                    chooseVariation(exp, foundVar)
                    continue
                }
            }

            // choose new variation for experiment
            chooseVariation(exp, chooseVariationFromExperiment(exp))
        }
    }

    // add default value of variables to dynamicVars so it doesn't try and re-create non active variables
    if (data.variables) {
        for (let variable of data.variables) {
            if (!dynamicVars || dynamicVars[variable.name]) continue
            dynamicVars[variable.name] = variable
        }
    }

    return {
        expVarsNamesHistory: cachedExpVarsNames,
        expVarsNames: expVarsNames,
        cachedExpIds: cachedExpIds,
        expVarsIds: expVarsIds,
        dynamicVars: dynamicVars
    }
}

exports.findWinningVariation = findWinningVariation
function findWinningVariation(exp) {
    if (!exp || !exp.winning_variation) return null
    if (exp.winning_variation === "baseline") return "b"

    for (const variation of exp.variations) {
        if (variation && exp.winning_variation === variation._id) {
            return variation
        }
    }
    return null
}

exports.chooseVariationFromExperiment = chooseVariationFromExperiment
function chooseVariationFromExperiment(exp) {
    if (exp.variations.length === 0) return "baseline"

    let rand = Math.random()
    let baselinePer = _.isNumber(exp.baseline.distributionPercent) ? exp.baseline.distributionPercent : 0

    if (baselinePer && (rand < baselinePer)) {
        log.log("Show Baseline For Experiment: " + exp._id, null, log.DEBUG)
        return "baseline"
    } else {
        let per = baselinePer
        for (const variation of exp.variations) {
            if (variation.distributionPercent) {
                per += variation.distributionPercent
                if (rand < per) {
                    log.log(`Show Variation: ${variation._id}, for experiment: ${exp._id}`, null, log.DEBUG)
                    return variation
                }
            }
        }

        log.error("Didn't find variation in experiment", null, log.DEBUG)
        return "baseline"
    }
}
