const log = require('../lib/logger')
const session = require('../lib/session')
const cookies = require('../lib/cookies')
const domObserver = require('../visualEditor/domObserver')

const interceptAdobeAnalytics = require('../integrations/adobeAnalytics')
const { logToIntegrations, activateIntegration } = require('../integrations')
const _ = require('../lib/tools')

let auto_page_view = true

module.exports = function(token, options) {
    if (!isValidToken(token)) {
        log.error("An SDK token is required.", null, log.USER)
        return undefined
    }

    this.env = "production"

    if (options) {
        this.api.config.startOptions = options
        if (_.isNumber(options.log_level)) {
            log.setPriorityLevel(options.log_level)
        }

        try {
            if (options.alias_host) {
                const {api_host, ping_host} = _.isString(options.alias_host) ? JSON.parse(options.alias_host) : options.alias_host
                if (api_host) {
                    this.api.config.apiAlias = api_host
                }
                if (ping_host) {
                    this.api.config.pingAlias = ping_host
                }
            }
        } catch (e) {
            log.error('Error trying to convert alias host to JSON', e)
        }

        if (options.auto_page_view === false) {
            auto_page_view = false
        }

        if (options.env) {
            this.env = options.env
        }

        if (options.test_experiments) {
            let test_experiments = options.test_experiments
            if (typeof test_experiments === 'object') {
                session.test_experiments = test_experiments
            } else if (typeof test_experiments === 'string') {
                try {
                    session.test_experiments = JSON.parse(test_experiments)
                } catch (err) {
                    log.error('Error parsing test experiments', err)
                }
            }
        }

        if (options.fast_mode) {
            this.api.config.fastMode = options.fast_mode
        }

        if (options.cookie_domain) {
            this.api.config.cookieDomain = options.cookie_domain
        }

        if (options.user_bucketing) {
            this.api.config.userBucketing = options.user_bucketing
        }

        if (_.isNumber(options.timeout)) {
            this.api.request.setTimeout(options.timeout)
        }

        // used to get the anonymous user id created from the server for user bucketing
        if (options.anon_user && !session.getUserID()) {
            session.setUserID(options.anon_user)
        }

        if (options.track_adobe_analytics) {
            interceptAdobeAnalytics(options.adobe_obj_name, this.api.events.trackAdobeAnalyticsEvents)
        }

        if (options.manual_trigger_edits) {
            domObserver.manualTriggerEdits = options.manual_trigger_edits
        }
    }

    /* Initialization */
    // internal data
    this._in = {}
    this._in.token   = token

    // Expose this, in case we want to override log level after initialization.
    this._in.logger  = log
    this._in.cookies = cookies

    // Let API know about our token.
    this.api.init(this)

    const dynamicConfig = (options && options.config) ? options.config : undefined
    const deviceUUID = dynamicConfig ? dynamicConfig.ad : undefined
    /* Start a session */
    session.start(deviceUUID)

    let user_attributes = null

    try {
        if (_.isString(options.user_attributes)) {
            user_attributes = options && options.user_attributes && JSON.parse(options.user_attributes)
        } else if (_.isObjectLike(options.user_attributes)) {
            user_attributes = options.user_attributes
        }
    } catch (err) {
        log.error('Error trying to convert user attributes to JSON', err)
    }

    this.api.config.get({dynamicConfig, user_attributes})

    if (auto_page_view && this.page) {
        this.page()
    }

    // Initiate flushQueue:
    this.api.events.scheduleTick()

    // track
    this.api.events.watchLifecycleEvents()

    session.configPromise(() => {
        const config = session.config
        const expVars = config && config.expVarsNames
        const integrations = config && config.integrations || {}
        Object.keys(integrations).forEach((integration) => {
            if (integrations[integration]) {
                activateIntegration(integration)
            }
        })
        logToIntegrations(expVars)
    })

    return this
}

// Helper functions
const isValidToken = (token) => {
    if (!token || typeof token !== "string") {
        return false
    }

    return !!token.length
}
