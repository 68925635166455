const request = require('./base')
const log = require('../lib/logger')
const session = require('../lib/session')

exports.post = (variable, next) => {
    if (!variable) return log.error("No new variable to post to server", null, log.DEBUG)

    let time = new Date()
    let body = {
        name: variable.name,
        createdAt: new Date(),
        variableType: variable.defaultType,
        defaultVal: variable.stringifyValue ? variable.stringifyValue(variable.defaultValue) : variable.defaultValue
    }

    log.log("variable_post", body, log.DEBUG)
    request.post("variable", {}, body, (err, response) => {
        if (err) {
            log.error("Failed to post variable", err, log.DEBUG)
        } else {
            let newVariable = response.body
            if (newVariable && newVariable.name) {
                log.time("Users.post: successfully created new variable.", newVariable, time, log.DEBUG)
            }
        }
        return next && next(err)
    })
}
