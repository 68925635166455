const _ = require('../lib/tools.js')

exports.buildUrlFromLocation = (location) => {
    if (!location || !location.origin) {
        return null
    } else {
        return location.origin + (location.pathname ? location.pathname : "")
    }
}

exports.matchUrlsByPath = (url1, url2) => {
    let location1 = exports.getLocationFromUrl(url1)
    let location2 = exports.getLocationFromUrl(url2)

    if (!location1 || !location2 || !location1.origin.length || !location2.origin.length) {
        // Couldn't parse url into location objects, try direct comparison to pathnames
        url1 = url1.replace(/^(http(s?):\/\/)?((www\.)?)((.*\/)?)/, "")
        url2 = url2.replace(/^(http(s?):\/\/)?((www\.)?)((.*\/)?)/, "")
        return url1 === url2
    }
    return location1.pathname === location2.pathname
}

exports.matchUrlsByDomain = (url1, url2) => {
    url1 = url1.replace(/^(http(s?):\/\/)?((www\.)?)/, "").split("/")[0];
    url2 = url2.replace(/^(http(s?):\/\/)?((www\.)?)/, "").split("/")[0];
    url1 = url1.split(".").slice(-2)
    url2 = url2.split(".").slice(-2)
    return _.isEqual(url1, url2)
}

exports.getLocationFromUrl = (url) => {
    let l = document.createElement("a")
    l.href = url
    if (!l.origin.length) return null

    return {
        origin: l.origin,
        hostname: l.hostname,
        pathname: l.pathname,
        href: l.href
    }
}

exports.matchUrlRules = (urlRules, url) => {
    if (!urlRules || !urlRules.length || !url) return false

    let isMatch = false

    _.forEach(urlRules, (({ comparator, value }) => {
        if (comparator === "equals" || comparator === "=" || comparator === "is") {
            isMatch = url === value
        } else if (comparator === "not_equals" || comparator === "!=" || comparator === "is not") {
            isMatch = url !== value
        } else if (comparator === 'contains' || comparator === 'contain') {
            isMatch = url.includes(value)
        } else if (comparator === 'does not contain' || comparator === '!contain') {
            isMatch = !url.includes(value)
        } else if (comparator === 'match') {
            const regex = new RegExp(value)
            isMatch = regex.test(url)
        } else if (comparator === '!match') {
            const regex = new RegExp(value)
            isMatch = !regex.test(url)
        }

        // Break out early if there is a match
        if (isMatch) return false
    }))

    return isMatch
}
