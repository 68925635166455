const log = require('../lib/logger')
const session = require('../lib/session')

module.exports = function() {
    if (!this.isReady()) {
        log.error("Taplytics::startNewSession: you have to call Taplytics.init first.", null, log.USER)
        return this
    }
    session.tick()
    session.resetSession()
    this.api.config.get({})
    return this
}