const hasDefaultAdobeObj = () => window.s !== undefined && (typeof window.s.t === 'function') && (typeof window.s.tl === 'function')

const overrideTrackingFunctions = (adobeObj, trackFn) => {
    const tFunction = adobeObj.t
    const tlFunction = adobeObj.tl

    // https://marketing.adobe.com/resources/help/en_US/sc/implement/function_t.html
    adobeObj.t = () => {
        events(adobeObj).forEach(event => {
            trackFn(event.name, event.value, attrsFrom(adobeObj))
        })
        return tFunction.bind(adobeObj)()
    }

    // https://marketing.adobe.com/resources/help/en_US/sc/implement/function_tl.html
    adobeObj.tl = (linkObject, linkType, linkName, variableOverrides, doneAction) => {
        events(adobeObj).forEach(event => {
            const attrs = Object.assign({}, attrsFrom(adobeObj), { linkType, linkName })

            // https://marketing.adobe.com/resources/help/en_US/sc/implement/linkTrackEvents.html
            if (adobeObj.linkTrackEvents && adobeObj.linkTrackEvents.includes(event.name)) {
                trackFn(event.name, event.value, attrs)
            }
        })
        return tlFunction.bind(adobeObj)(linkObject, linkType, linkName, variableOverrides, doneAction)
    }
}

// event1=4 => { name: 'event1', value: '4' }
const intoNameAndValue = event => {
    return { name: event.split('=')[0], value: event.split('=')[1] || null }
}

// https://marketing.adobe.com/resources/help/en_US/sc/implement/events.html
// event1=2,event2=3 => [{ name: 'event1', value: '2' }, { name: 'event2', value: 3 }]
const events = adobeObj => {
    if (adobeObj.events && adobeObj.events !== 'None') {
        return adobeObj.events.split(',').map(intoNameAndValue)
    } else {
        return []
    }
}

const keysWithoutFuncAndObj = adobeObj => {
    return Object.keys(adobeObj)
          .filter(key => typeof adobeObj[key] !== 'function'
                         && typeof adobeObj[key] !== 'object'
                         && key !== 'events'
                         && key !== 'linkTrackEvents')
}

const attrsFrom = adobeObj => {
    let attrs = {}
    keysWithoutFuncAndObj(adobeObj).forEach(key => { attrs[key] = adobeObj[key] })
    return attrs
}

const trackThePageLoadCall = (adobeObj, trackFn) => {
    events(adobeObj).forEach(event => {
        trackFn(event.name, event.value, attrsFrom(adobeObj))
    })
}

let attempts = 0

const findAdobeObjAndOverrideIt = (adobeObjName, trackFn) => {

    const adobeObj = adobeObjName ? window[adobeObjName] : window.s

    if (attempts < 50 && adobeObj !== undefined) {
        overrideTrackingFunctions(adobeObj, trackFn)
        trackThePageLoadCall(adobeObj, trackFn)
    } else {
        attempts += 1
        setTimeout(() => {
            findAdobeObjAndOverrideIt(adobeObjName, trackFn)
        }, 200)
    }
}

module.exports = function(adobeObjName, trackFn) {
    findAdobeObjAndOverrideIt(adobeObjName, trackFn)
}
