const { logTo: logToMixpanel } = require('./mixpanel')

exports.integrationStatus = {
    mixpanel: false,
    // integrations go here
}

const activateIntegration = (name) => {
    exports.integrationStatus[name] = true
}

const integrationLogFns = {
    mixpanel: logToMixpanel
}

const logToIntegrations = (expVars) => {
    Object.keys(exports.integrationStatus)
        .filter(intgr => exports.integrationStatus[intgr]).forEach((intgr) => {
            const logFn = integrationLogFns[intgr]
            logFn && logFn(expVars)
        })
}

exports.logToIntegrations = logToIntegrations
exports.activateIntegration = activateIntegration
